import * as React from 'react';
import {Fragment} from 'react';
import useSWR from 'swr';
import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL;

const AuthContext = React.createContext(undefined);

function UserProvider(props) {
  const fetcher = async (url) => {
    const res = await axios.get(url, {withCredentials: true, validateStatus: () => true});
    return {
      success: true,
      user: (res.data === false) ? null : res.data,
    };
  };

  const {data, mutate: reloadUser, error} = useSWR(`${API_URL}/auth/me`, fetcher);

  if (error) {
    return (
      <Fragment>
        <div className="main-content pt-0 bg-white ps-0 pe-0">
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-lg-6 col-md-8 text-center default-page vh-100 align-items-center d-flex">
                <div className="card border-0 text-center d-block p-0">
                  <h1 className="fw-700 display3-size display4-md-size" style={{color: '#8b0000'}}>An error occurred!</h1>
                  <p className="text-danger font-xsss">Please report the following error: {error.message}</p> <br />
                  <div style={{
                    backgroundColor: '#252525',
                    borderRadius: '16px',
                    color: '#fff',
                    fontSize: 'smaller',
                  }} className="p-3">
                    {JSON.stringify(error)}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  if (!data) {
    return <div></div>;
  }

  const user = data.user;

  return (
    <AuthContext.Provider value={{user, reloadUser}} {...props} />
  );
}

const useUser = () => React.useContext(AuthContext);

export {UserProvider, useUser};

// the UserProvider in user-context.js is basically:
// const UserProvider = props => (
//   <UserContext.Provider value={useAuth().data.user} {...props} />
// )
// and the useUser hook is basically this:
// const useUser = () => React.useContext(UserContext)
