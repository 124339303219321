import {Fragment, useEffect, useState} from 'react';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import RequireUser from '../components/RequireUser';
import axios from 'axios';
import {NotificationResponse} from '../api-types';
import Notification from '../components/global/Notification';
import {GrFormNext, GrFormPrevious} from 'react-icons/gr';
import {BsCheckLg} from 'react-icons/bs';
import Alert from '../components/Alert';

export default function Notifications() {
  const pageSize = 10;

  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [confirmingMarkRead, setConfirmingMarkRead] = useState(false);

  function loadNotifications() {
    if (loading) return;
    axios.get(`${process.env.REACT_APP_API_URL}/profiledata/notifications?skip=${current}&take=20`, {withCredentials: true})
        .then((res) => {
          setCurrent(current+20);
          const data: NotificationResponse = res.data;
          setUnreadCount(data.unreadCount);
          setNotifications(notifications.concat(data.notifications));
          setHasMore(data.notifications.length === 20);
          setLoading(false);
        });
  }

  async function markAllAsRead() {
    if (unreadCount === 0) return;
    setNotifications(notifications.map((noti) => {
      noti.read = true;
      return noti;
    }));
    setUnreadCount(0);
    await axios.post(`${process.env.REACT_APP_API_URL}/profiledata/notifications`, {action: 'READALL'}, {withCredentials: true});
  }

  useEffect(() => {
    if (hasMore && notifications.length < (page+2)*20) {
      loadNotifications();
    }
  }, [page, notifications]);


  return (
    <RequireUser>
      <Header />
      <Leftnav />
      <Rightchat />

      {confirmingMarkRead && <Alert
        dismissible={true}
        title={`You are about to mark all notifications as read`}
        content={`Are you sure you want to mark all your notifications as read?`}
        onClose={() => setConfirmingMarkRead(false)}
        onConfirm={() => {
          markAllAsRead().then((r) => {});
          setConfirmingMarkRead(false);
        }}/>}

      <div className="main-content theme-dark-bg right-chat-active">

        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-lg-12">
                <div className="chat-wrapper p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                  <h2 className="fw-700 mb-4 mt-2 font-md text-grey-900 d-flex align-items-center">Notifications
                    <span className="circle-count bg-warning text-white font-xsssss rounded-3 ms-2 ls-3 fw-600 p-2  mt-0">{unreadCount}</span>
                    <div className="ms-auto btn-round-sm bg-greylight rounded-3 cursor-pointer" title="Mark all as read" onClick={()=>setConfirmingMarkRead(true)}><BsCheckLg color={'#4b4b4b'}/></div></h2>
                  <ul className="notification-box">
                    {notifications.slice(page*pageSize, (page+1)*pageSize).map((data, index) => <Notification notification={data} key={index} table={true}/> )}
                  </ul>
                  <span className="w-100 d-flex mt-3 pagination">
                    <GrFormPrevious title="Go to previous page" onClick={() => {
                      setPage(Math.max(0, page-1));
                    }} size={26} className="ms-auto" />
                    Page {page+1}
                    <GrFormNext title="Go to next page" onClick={() => {
                      setPage(Math.min(Math.floor(notifications.length/pageSize), page+1));
                    }} size={26} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </RequireUser>
  );
}
