import {useEffect, useRef, useState} from 'react';
import Load from '../Load';
import axios from 'axios';
import {FeedEntry, FeedResponse} from '../../api-types';
import PrimaryFeedEntry from './PrimaryFeedEntry';

export default function PrimaryFeed() {
  const entriesRef = useRef();
  const [hasMore, setHasMore] = useState(true);
  const [entries, setEntries] = useState<FeedEntry[]>([]);
  const [loading, setLoading] = useState(false);

  const more = (reset:boolean = false) => {
    if (loading || !hasMore) return;
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/feeds/primary${reset ? '?reset_cursor=true': ''}`, {withCredentials: true})
        .then((res) => {
          const data: FeedResponse = res.data;
          console.log(data);
          setEntries(entries.concat(data.entries));
          setHasMore(data.has_more);
          setLoading(false);
        });
  };

  const onScroll = () => {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 200) {
      more();
    }
  };

  useEffect(() => {
    more(true);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  return (
    <div ref={entriesRef}>
      {entries.flatMap((entry, index) => (<PrimaryFeedEntry key={index} entry={entry}/>))}
      {hasMore && <Load/>}
    </div>
  );
}
