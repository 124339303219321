import Notification from '../global/Notification';
import {Link} from 'react-router-dom';
import {FaPlus} from 'react-icons/fa';
import {NotificationResponse} from '../../api-types';
import {RiMoonFoggyFill} from 'react-icons/ri';

type NotificationsProps = {
    open: boolean;
    data?: NotificationResponse
}

export default function Notifications({open, data} :NotificationsProps) {
  if (!data) return 'Loading notifications...';
  return (<div style={{maxWidth: 500}} className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${open ? 'show' : ''}`}
    aria-labelledby="dropdownMenu3">
    <h4 className="fw-700 font-xss mb-4">Notifications <Link to="/notifications" className="font-xsssss">view all</Link></h4>
    {data?.notifications === undefined ? 'loading...' : ( data.notifications.length > 0 ?
        data.notifications.map((notification, index) =>
          <Notification key={index} notification={notification}/>,
        ) :
        <div className="card bg-transparent-card w-100 border-0 ps-5">
          <RiMoonFoggyFill size={28} className="w40 position-absolute left-0"/>
          <span className="h6 text-grey-500 fw-500 font-xssss" style={{lineHeight: '30px'}}>No new notifications</span>
        </div>)}
    {data.unreadCount > 5 && (
      <div className="card bg-transparent-card w-100 border-0 ps-5">
        <FaPlus className="w40 position-absolute left-0"/>
        {/* <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">{notification.comment.postedBy.name} <span*/}
        {/*  className="text-grey-400 font-xsssss fw-600 float-right mt-1">{getRelativeTime(new Date(notification.date))}</span>*/}
        {/* </h5>*/}
        <Link to="/notifications" className="h6 text-grey-500 fw-500 font-xssss">{data.unreadCount - 5} more unread notifications... </Link>
      </div>)}
  </div>
  );
}
