
export default function PostCardComment({comment, post}) {
  const isOP = comment.postedBy.tag === post.postedBy.tag;

  return <div className="card-body p-0 mt-3 position-relative w-100">
    {isOP ? <>
      <div
        className="bor-0 rounded-xxl p-2 ms-auto me-5 font-xssss text-grey-900 fw-500 border-light-md theme-dark-bg post-comment isOp">
        <span className="fw-700 text-dark-white text-grey-900">{comment.postedBy.name}</span> <span
          className="text-grey-700">@{comment.postedBy.tag}</span> <br/>
        <p>{comment.message}</p>
      </div>
      <figure className="avatar position-absolute ms-2 mt-1 top-5 end-0">
        <img src={`${process.env.REACT_APP_API_URL}/image/${comment.postedBy.pictureUid}/thumb.webp`}
          alt="icon"
          className="shadow-sm rounded-circle w30"/>
      </figure>
    </> :
            <>
              <figure className="avatar position-absolute ms-2 mt-1 top-5">
                <img src={`${process.env.REACT_APP_API_URL}/image/${comment.postedBy.pictureUid}/thumb.webp`}
                  alt="icon"
                  className="shadow-sm rounded-circle w30"/>
              </figure>
              <div
                className="bor-0 rounded-xxl p-2 ms-5 font-xssss text-grey-900 fw-500 border-light-md theme-dark-bg post-comment">
                <span className="fw-700 text-dark-white text-grey-900">{comment.postedBy.name}</span> <span
                  className="text-grey-700">@{comment.postedBy.tag}</span> <br/>
                <p>{comment.message}</p>
              </div>
            </>}

  </div>;
}
