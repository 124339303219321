import React, {Fragment} from 'react';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';


import Createpost from '../components/Createpost';
import FollowRequests from '../components/widgets/FollowRequests';
import NewProfiles from '../components/widgets/NewProfiles';
import PrimaryFeed from '../components/feeds/PrimaryFeed';
import {useUser} from '../helpers/auth-context';
import SuggestedProfiles from '../components/widgets/SuggestedProfiles';


export default function Home() {
  const {user} = useUser();

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body">
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                {/* <Storyslider />*/}
                {user?.activeProfile ? <Createpost /> : (!user ? <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                  <div className="card-body p-0">
                    <a href="/" className="font-xssss fw-600 text-grey-900 card-body p-0 d-flex align-items-center"><i
                      className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Join Hello Today!</a>
                  </div>
                  <div className="card-body p-0 mt-3 position-relative">
                    <span className="text-grey-700 text-dark-gray">Join Karakura&#39;s best and only social media platform today!</span>
                    <a href="/register" className="header-btn d-block ms-auto bg-current fw-500 text-white font-xsss p-2 ms-2 w100 text-center lh-20 rounded-xl">Register</a>
                  </div>
                </div> : <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                  <div className="card-body p-0">
                    <a href="/" className="font-xssss fw-600 text-grey-900 card-body p-0 d-flex align-items-center"><i
                      className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Create your first profile!</a>
                  </div>
                  <div className="card-body p-0 mt-3 position-relative">
                    <span className="text-grey-700 text-dark-gray">Create your first profile and start using Hello!</span>
                    <a href="/profile/create" className="header-btn d-block ms-auto bg-current fw-500 text-white font-xsss p-2 ms-2 w100 text-center lh-20 rounded-xl">Create Profile</a>
                  </div>
                </div>)}
                <PrimaryFeed />

                {/* <Postview id="32" postvideo="" postimage="post.png" avater="user.png" user="Surfiya Zakir" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                  <Postview id="31" postvideo="" postimage="post.png" avater="user.png" user="David Goria" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                  <Postview id="33" postvideo="" postimage="post.png" avater="user.png" user="Anthony Daugloi" time="2 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                  <Memberslider />
                  <Postview id="35" postvideo="" postimage="post.png" avater="user.png" user="Victor Exrixon" time="3 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                  <Friendsilder />
                  <Postview id="36" postvideo="" postimage="post.png" avater="user.png" user="Victor Exrixon" time="12 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                  <Load />*/}

              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                <FollowRequests />
                <SuggestedProfiles/>
                <NewProfiles />
                {/* <Contacts />*/}
                {/* <Group />*/}
                {/* <Events />*/}
                {/* <Profilephoto />*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}
