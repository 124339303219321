import useSWR from 'swr';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {useUser} from '../../helpers/auth-context';


export default function SuggestedProfiles() {
  const {user} = useUser();

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data, error} = useSWR(`${process.env.REACT_APP_API_URL}/feeds/suggested-profiles`, fetcher);

  if (!user?.activeProfile || data === undefined || data.data.lenght < 1) return '';

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 pb-3">
      <div className="card-body d-flex align-items-center p-4">
        <h4 className="fw-700 mb-0 font-xssss text-grey-900">Suggested Profiles</h4>
      </div>
      {data.data.map((entry, index) => (
        <div className="wrap" key={index}>
          <Link to={`/p/${entry.profileTag}`}
            className="card-body d-flex ps-2 pe-2 pb-0 bor-0 p-2 bg-greylight bg-themedark-light ms-3 me-3 rounded-3 mb-2">
            <figure className="avatar me-3 m-0 mb-2"><img
              src={`${process.env.REACT_APP_API_URL}/image/${entry.profilePictureUid}/thumb.webp`} alt="avater"
              className="shadow-sm rounded-circle w45"/></figure>
            <h4 className="fw-700 text-grey-900 font-xssss mt-1">{entry.profileName} <span
              className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Followed by {entry.mutualFollowers} {entry.mutualFollowers == 1 ? 'person' : 'people'} you are following</span>
            </h4>
          </Link>
        </div>
      ))}
    </div>
  );
}

