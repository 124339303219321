import React, {Fragment} from 'react';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

import Profiledetail from '../components/Profiledetail';
import Profilephoto from '../components/Profilephoto';
import ProfilecardTwo from '../components/ProfilecardTwo';
import Createpost from '../components/Createpost';
import Events from '../components/Events';
import Postview from '../components/Postview';
import Load from '../components/Load';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import useSWR from 'swr';
import Notfound from './Notfound';
import {useUser} from '../helpers/auth-context';
import PrivateProfileCard from '../components/PrivateProfileCard';
import ProfilePostList from '../components/ProfilePostList';

const API_URL = process.env.REACT_APP_API_URL;

export default function ProfilePage() {
  const {tag} = useParams();

  const {user} = useUser();

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data} = useSWR(`${API_URL}/profile/${tag}`, fetcher);

  if (!data) return (<></>);

  if (!data.data.success && data.data.errno === 12) {
    return <Notfound />;
  }

  const isOwn = user && data.data.profile.id === user.activeProfile.profile.id;
  const canView = data.data.canview;

  return (
    <Fragment>
      <Header/>
      <Leftnav/>
      <Rightchat/>

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <ProfilecardTwo profile={data.data.profile} own={isOwn}/>
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-lg-0">
                <Profiledetail profile={data.data.profile} own={isOwn}/>
                {/* <Profilephoto/>
                                <Events/>*/}
              </div>
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                {isOwn &&
                                    <Createpost />
                }
                {canView ?
                                    <ProfilePostList profile={data.data.profile}/> :
                                    <PrivateProfileCard />
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat/>
      <Appfooter/>

    </Fragment>
  );
}
