import React, {useEffect, useRef, useState} from 'react';
import Load from './Load';
import PostCard from './PostCard';
import axios from 'axios';

export default function ProfilePostList({profile}) {
  const postsRef = useRef();
  const [hasMore, setHasMore] = useState(true);
  const [posts, setPosts] = useState([]);

  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);

  const more = () => {
    if (loading || !hasMore) return;
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/profile/${profile.tag}/posts?skip=${current}&take=10`, {withCredentials: true})
        .then((res) => {
          const cPosts = posts;
          console.log('data: ', res.data)
          ;
          setPosts(cPosts.concat(res.data));
          setCurrent(current+10);
          setLoading(false);
          setHasMore(res.data.length === 10);
        });
  };

  useEffect(() => more());
  console.log(posts);
  const onScroll = () => {
    if (postsRef.current) {
      const {scrollTop, scrollHeight, clientHeight} = postsRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        more();
      }
    }
  };


  return (
    <div onScroll={() => onScroll()} ref={postsRef}>
      {posts.flatMap((p, index) => (<PostCard post={p}/>))}
      {hasMore && <Load/>}
    </div>
  );
}
