import React, {Component, Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import {useUser} from '../helpers/auth-context';

const API_URL = process.env.REACT_APP_API_URL;

class RegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: props.user !== null,
      username: '',
      password: '',
      repeatPassword: '',
      phonenumber: '',
      terms: false,
      error: {
        username_invalid: false,
        password: false,
        password_not_match: false,
        username_exists: false,
        phonenumber_used: false,
        phonenumber_invalid: false,
        terms: false,
      },
      loading: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    axios.get(`${API_URL}/auth/me`, {withCredentials: true})
        .then((res) => {
          if (res.data != false) {
            this.setState({
              loggedIn: true,
            });
          }
        });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.loading) return;

    this.setState({
      error: {
        username_invalid: !/\w{3,}/.test(this.state.username),
        password: !(this.state.password.length > 6) ||
                    !/[a-z]/.test(this.state.password) ||
                    !/[A-Z]/.test(this.state.password) ||
                    this.state.username.toLowerCase() == this.state.password.toLowerCase(),
        password_not_match: this.state.password !== this.state.repeatPassword,
        username_exists: false,
        phonenumber_used: false,
        phonenumber_invalid: !/^030\d{3,}$/.test(this.state.phonenumber),
        terms: !this.state.terms,
      },
    });

    if (Object.values(this.state.error).find((o) => o === true)) {
      return;
    }

    this.setState({loading: true});
    console.log('Registering...');
    axios.post(`${API_URL}/auth/register`, {
      username: this.state.username,
      password: this.state.password,
      phonenumber: this.state.phonenumber,
    })
        .then((res) => {
          const data = res.data;
          console.log(data);
          if (data.type === 'ERROR') {
            if (data.errno === 12) {
              this.setState({error: {username_exists: true}, loading: false});
            } else if (data.errno === 13) {
              this.setState({error: {phonenumber_used: true}, loading: false});
            } else {
              this.setState({loading: false});
            }
          } else if (data.type === 'OK') {
            axios.post(`${API_URL}/auth/login`, {
              username: this.state.username,
              password: this.state.password,
            }, {withCredentials: true})
                .then((res) => {
                  if (res.data === 'OK') {
                    this.setState({loggedIn: true});
                  }
                });
          }
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => this.setState({loading: false}));
  }


  render() {
    if (this.state.loggedIn) {
      return (
        <Redirect to={`/`}/>
      );
    } else {
      return (
        <Fragment>
          <div className="main-wrap">
            <div className="nav-header bg-transparent shadow-none border-0">
              <div className="nav-top w-100">
                <a href="/"><i className="feather-zap text-success display1-size me-2 ms-0"></i><span
                  className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">Sociala. </span>
                </a>
                <button className="nav-menu me-0 ms-auto"></button>

                <a href="/login"
                  className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                <a href="/register"
                  className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a>
              </div>
            </div>


            <div className="row">
              <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                style={{backgroundImage: `url("https://via.placeholder.com/800x950.png")`}}></div>
              <div
                className="col-xl-7 align-items-center d-flex bg-white rounded-3 centered-card">
                <div className="card shadow-none border-0 ms-auto me-auto login-card">
                  <div className="card-body rounded-0 text-left">
                    <h2 className="fw-700 display1-size display2-md-size mb-4">Create <br/>your
                                            account</h2>
                    <form>

                      <div className="form-group icon-input mb-3">
                        <i className="font-sm ti-user text-grey-500 pe-0"></i>
                        <input type="text"
                          name="username"
                          value={this.state.username}
                          onChange={this.handleInputChange}
                          className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${(this.state.error.username_exists || this.state.error.username_invalid) && 'is-invalid'}`}
                          placeholder="Your Username"/>
                        <div className="invalid-feedback text-red font-xsss fw-500 ">
                          {this.state.error.username_invalid && 'Username can only contain letters and must be at least 3 characters long.'}
                          {this.state.error.username_exists && 'This username already exists.'}
                        </div>
                      </div>
                      <div className="form-group icon-input mb-3">
                        <i className="font-sm ti-mobile text-grey-500 pe-0"></i>
                        <input type="text"
                          name="phonenumber"
                          value={this.state.phonenumber}
                          onChange={this.handleInputChange}
                          className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${(this.state.error.phonenumber_invalid || this.state.error.phonenumber_used) && 'is-invalid'}`}
                          placeholder="Your In Game Phone Number "/>
                        <div className="invalid-feedback text-red font-xsss fw-500 ">
                          {this.state.error.phonenumber_invalid && 'Invalid phone-number, make sure it\'s written like 030000000'}
                          {this.state.error.phonenumber_used && 'This phone-number is already in use.'}
                        </div>
                      </div>
                      <div className="form-group icon-input mb-1">
                        <input type="Password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleInputChange}
                          className={`style2-input ps-5 form-control text-grey-900 font-xss ls-3 ${(this.state.error.password || this.state.error.password_not_match) && 'is-invalid'}`}
                          placeholder="Password"/>
                        <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                      </div>
                      <div className="mb-3 bg-grey p-2 rounded-3">
                        <div>
                          <input type="checkbox"
                            className={`form-check-input mt-2 me-2 ${this.state.error.password && !(this.state.password.length > 6) && 'is-invalid'}`}
                            checked={this.state.password.length > 6} readOnly={true}/>
                          <label className="form-check-label font-xsss checkbox-text pt-1">6
                                                        characters long</label>
                        </div>
                        <div>
                          <input type="checkbox"
                            className={`form-check-input mt-2 me-2 ${this.state.error.password && !/[a-z]/.test(this.state.password) && 'is-invalid'}`}
                            checked={/[a-z]/.test(this.state.password)} readOnly={true}/>
                          <label className="form-check-label font-xsss checkbox-text pt-1">1
                                                        lowercase letter</label>
                        </div>
                        <div>
                          <input type="checkbox"
                            className={`form-check-input mt-2 me-2 ${this.state.error.password && !/[A-Z]/.test(this.state.password) && 'is-invalid'}`}
                            checked={/[A-Z]/.test(this.state.password)} readOnly={true}/>
                          <label className="form-check-label font-xsss checkbox-text pt-1">1
                                                        uppercase letter</label>
                        </div>
                        <div>
                          <input type="checkbox"
                            className={`form-check-input mt-2 me-2 ${this.state.error.password && this.state.username.toLowerCase() == this.state.password.toLowerCase() && 'is-invalid'}`}
                            checked={this.state.username.toLowerCase() != this.state.password.toLowerCase()}
                            readOnly={true}/>
                          <label className="form-check-label font-xsss checkbox-text pt-1">Different
                                                        from username</label>
                        </div>
                      </div>
                      <div className="form-group icon-input mb-1">
                        <input type="Password"
                          name="repeatPassword"
                          value={this.state.repeatPassword}
                          onChange={this.handleInputChange}
                          className={`style2-input ps-5 form-control text-grey-900 font-xss ls-3 ${this.state.error.password_not_match && 'is-invalid'}`}
                          placeholder="Confirm Password"/>
                        <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                        <div className="invalid-feedback text-red font-xsss fw-500 ">
                                                    Passwords do not match
                        </div>
                      </div>
                      <div className="form-check text-left mb-3">
                        <input type="checkbox"
                          className={`form-check-input mt-2 ${this.state.error.terms && 'is-invalid'}`}
                          name="terms" checked={this.state.terms}
                          onChange={this.handleInputChange}/>
                        <label className="form-check-label font-xsss checkbox-text pt-1">Accept
                                                    Term
                                                    and Conditions</label>
                        <div className="invalid-feedback text-red font-xsss fw-500 ">
                                                    You must accept the terms and conditions to create an account.
                        </div>

                      </div>
                    </form>

                    <div className="col-sm-12 p-0 text-left">
                      <div className="form-group mb-1"><a href='#' onClick={this.handleSubmit}
                        className={`form-control text-center style2-input text-white fw-600 ${this.state.loading ? 'bg-darkish' : 'bg-dark'} border-0 p-0 `}>Register</a>
                      </div>
                      <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Already have
                                                account <a href="/login" className="fw-700 ms-1">Login</a></h6>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </Fragment>
      );
    }
  }
}

export default function Register() {
  const {user} = useUser();
  return <RegisterPage user={user} />;
};
