import React, {Fragment, useEffect, useState} from 'react';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import {useUser} from '../helpers/auth-context';

const API_URL = process.env.REACT_APP_API_URL;

const requested = false;

export default function Logout() {
  const [complete, setComplete] = useState(false);
  const [finished, setFinished] = useState(false);

  const {reloadUser} = useUser();
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (!finished) {
      axios.get(`${API_URL}/auth/logout`, {withCredentials: true})
          .then(async (res) => {
            if (res.data != false) {
              setFinished(true);
            } else setError(res.data);
          })
          .catch((err) => setError(err.message));
    }
  });

  useEffect(() => {
    reloadUser().then(() => setComplete(true));
  }, [finished]);

  if (complete) {
    return (
      <Redirect to={`/login`} push={false}/>
    );
  } else {
    return (
      <Fragment>
        <div className="main-content pt-0 bg-white ps-0 pe-0">
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-lg-6 col-md-8 text-center default-page vh-100 align-items-center d-flex">
                <div className="card border-0 text-center d-block p-0">
                  {(error === undefined) && (<>
                    <h1 className="fw-700 text-grey-900 display3-size display4-md-size">Logging
                                            out...</h1>
                    <p className="text-grey-500 font-xsss">We are currently logging you out, you
                                            should be redirected shortly!</p>
                  </>)}
                  {(error !== undefined) && (<>
                    <h1 className="fw-700 display3-size display4-md-size"
                      style={{color: '#8b0000'}}>Log out
                                            failed!</h1>
                    <p className="text-danger font-xsss">Try to clear your cookies, if this issue
                                            persists please report the following error: {this.state.error}</p>
                  </>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
